import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../layout/layout";
import config from "../../../data/SiteConfig";
import Img from "gatsby-image";

import { Container, Section, Icon } from "bloomer";

export default class PurchaseCancel extends React.Component {
  render() {
    const template = (
      <Layout>
        <Helmet>
          <title>{`Shop | Success | ${config.siteTitle}`}</title>
        </Helmet>

        <Section>
          <Container>
            <hr />
            <h1 class="title is-1">Shop</h1>
            <hr />
            <p class="subtitle is-3">
              <span class="icon"><i class="fas fa-check-circle"></i></span> Purchase complete
            </p>
            <p>Thank you for your custom - your CD will soon be on its way!</p>
            <hr />
            <Link renderAs="button" class="button is-primary" to="/shop">
              <span>Continue browsing</span>{" "}
              <span class="icon">
                <i class="fas fa-chevron-right"></i>
              </span>
            </Link>
          </Container>
        </Section>
      </Layout>
    );
    return template;
  }
}
